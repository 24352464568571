import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import "./App.css";
import {
  explorerLink,
  fetchAssetImage,
  getAccountAssets,
  getAccountInfo,
  getAsset,
  getAssetImage,
  placeholderImage,
  randomArchirand,
  randomImage,
  shuffle,
  somethingFromSome,
} from "./functions";
import {
  cardClasses,
  CircularProgress,
  Divider,
  Grid,
  Modal,
  Paper,
  TextField,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import appService from "./services/appService";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// AssetSelector
import AssetSellector from "./components/AssetSelector";

// Noodle
import { useReach } from "./hooks/useReach";
import useLocalStorage from "./hooks/useLocalStorage";

import { styled } from "@mui/material/styles";

import React, { useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";

import { CubeTextureLoader, MeshBasicMaterial, CubeTexture } from "three";
import LogoCube from "./LogoCube";
import { ButtonGroup, Card, Col, Image, Row, Spinner } from "react-bootstrap";
import classNames from "classnames";
import * as relayBackend from "./build/relay/index.main.mjs";
import { getApplicationAddress } from "algosdk";
import * as diceBackend from "./build/dice/index.main.mjs";
import archirand from "./statics/archirand";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import { borderRadius } from "@mui/system";
import MouseIcon from "@mui/icons-material/Mouse";
import Icon from "react-crypto-icons";
import * as backend from "./build/dice/index.main.mjs";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import { Stage, Sprite } from "@inlet/react-pixi";
import POW from "./POWLOGO-black.svg";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const GRID_SIZE = 9;

const colors = [
  "#84DE02",
  "#E88E5A",
  "#DDE26A",
  "#C53151",
  "#FFDF46",
  "#B05C52",
  "#FF4466",
  "#828E84",
  "#FD5240",
  "#391285",
  "#FF85CF",
  "#FF4681",
  "#4BC7CF",
  "#FF6D3A",
  "#FF404C",
  "#A0E6FF",
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  maxWidth: "500px",
  //height: "80vh",
  background: "white",
  //border: "1px solid #ccc",
  boxShadow: 24,
  p: 5,
  textAlign: "center",
  paddingBottom: "5em",
  borderRadius: "1em",
  overflow: "hidden",
};

function Dice(props) {
  const loader = new CubeTextureLoader();
  loader.setPath("/");
  const images = randomArchirand(0).slice(0, 6);
  const textureCube = loader.load(images);
  const material = new MeshBasicMaterial({
    color: 0xffffff,
    envMap: textureCube,
  });

  // This reference gives us direct access to the THREE.Mesh object
  const ref = useRef();
  // Hold state for hovered and clicked events
  const [hovered, hover] = useState(false);
  const [clicked, click] = useState(false);
  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame((state, delta) => {
    ref.current.rotation.x += 0.01;
    ref.current.rotation.y += 0.01;
  });
  // Return the view, these are regular Threejs elements expressed in JSX
  return (
    <mesh
      {...props}
      ref={ref}
      scale={clicked ? 2 : 1}
      onClick={(event) => click(!clicked)}
      onPointerOver={(event) => hover(true)}
      onPointerOut={(event) => hover(false)}
    >
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={"orange"} />
    </mesh>
  );
}

const {
  REACT_APP_NETWORK_PROVIDER,
  REACT_APP_PPC_SHUFFLE,
  REACT_APP_PPC_DICE_GO,
  REACT_APP_PPC_DICE_ROLL,
} = process.env;

const providerEnv =
  REACT_APP_NETWORK_PROVIDER ||
  localStorage.getItem("providerEnv") ||
  "MainNet";

function App() {
  const { width, height } = useWindowSize();
  const reach = useReach();
  const [addr, setAddr] = useLocalStorage("addr", null);
  const navigate = useNavigate();
  const { poolId } = useParams();
  const [_, asset0, asset1, asset2, asset3, asset4, asset5, asset6] = poolId
    ? poolId.split("-")
    : [];
  const initialState = {
    acc: null,
    addrs:
      localStorage.getItem("state") &&
      (Object.keys(JSON.parse(localStorage.getItem("state"))?.memo2) || []).map(
        (el) => ({ addr: el })
      ),
    success: false,
    confetti: false,
  };
  const [goingToDice, setGoingToDice] = useState(false);
  const [display, setDisplay] = useState(null);
  const [showDiceModal, setShowDiceModal] = useState(false);
  const [loadingClaim, setLoadingClaim] = useState(false);
  const [open, setOpen] = useState(false);
  const [next, setNext] = useState(null);
  const [last, setLast] = useState(null);
  const [side, setSide] = useState(0);
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [speed, setSpeed] = useState(1000);
  const [query, setQuery] = useState({
    ASSET0: asset0,
    ASSET1: asset1,
    ASSET2: asset2,
    ASSET3: asset3,
    ASSET4: asset4,
    ASSET5: asset5,
    ASSET6: asset6,
  });
  const [selection, setSelection] = useState([]);
  const [counter, setCounter] = useState(-1);

  useEffect(() => {
    if (addr) {
      handleConnect();
    }
  }, []);

  useEffect(() => {
    if (!state.acc) return;
    const timeout = setTimeout(() => loadAssets(false), 1);
    return () => clearTimeout(timeout);
  }, [state.acc]);

  useEffect(() => {
    if (!state.acc) return;
    (async () => {
      const ctc = state.acc.contract(diceBackend, side);
      const next = somethingFromSome(
        (v) => reach.bigNumberToNumber(v),
        0
      )(await ctc.v.next());
      const price = somethingFromSome(
        (v) => reach.formatCurrency(v),
        0
      )(await ctc.v.price());
      const asset = await getAsset(next);
      //console.log({next: { ...asset, price }})
      //setNext({ ...asset, price });
    })();
  }, [side]);

  useEffect(() => {
    let counter = 0;
    const interval = setInterval(() => setCounter(counter++), speed);
    return () => clearInterval(interval);
  }, [speed]);

  const handleClose = () => window.location.reload();

  const handleChange = async ({ target }) => {
    let { name, value } = target;
    //console.log({ name, value });
    switch (name) {
      case "ASSETID":
        let { id: newId = 0, decimals: DECIMALS, creator: CREATOR } = value;
        // try again to get asset info if not in option value
        if (!DECIMALS) {
          let { decimals } = await getAsset(newId);
          DECIMALS = decimals;
        }
        setQuery({
          ...query,
          [name]: newId,
          DECIMALS,
        });
        break;
      case "SWAPT":
      case "SWAPF":
      case "INFO":
      case "EXCHANGE":
      case "PASS":
      case "PLAN":
      case "AMT":
      case "TYPE":
      case "METHOD":
      case "SKIPCHECK":
        value = parseInt(value);
        break;
      default:
        break;
    }
    setQuery({ ...query, [name]: value });
  };

  const handleConnect = async () => {
    try {
      //console.log("Connecting ...");
      let acc;
      if (addr) {
        acc = await reach.connectAccount({ addr });
      } else {
        acc = await reach.getDefaultAccount();
        setAddr(acc.networkAccount.addr);
      }
      //const balAtomic = await reach.balanceOf(acc);
      //const bal = reach.formatCurrency(balAtomic, 4);
      const balAtomic = reach.parseCurrency(0);
      const bal = 0;
      const assets = [];
      let next = undefined;
      do {
        const res = await getAccountAssets(
          //"LATYT7TD52YSITAJRJQLYLMV3BYESLHHEMAAQ5W2VAS6UB3RLA4LENU5WM",
          acc.networkAccount.addr,
          {
            next,
          }
        );
        //console.log({ res });
        next = res["next-token"];
        assets.push(res.assets);
      } while (next);
      setState({
        ...state,
        acc: {
          ...acc,
          assets: assets.flatMap((el) => el),
        },
        assets: assets.flatMap((el) => el),
        addr,
        balAtomic,
        bal,
      });
    } catch (e) {
      alert(e);
    }
  };

  const loadAssets = async (isShuffle = true) => {
    //console.log("ROLLING");
    const dices = shuffle(await appService.getDices());
    const sides = [];
    const bounty = [];
    for (let i in dices) {
      if (sides.length >= GRID_SIZE) {
        break;
      }

      const { appId } = dices[i];

      const ctc = state.acc.contract(backend, appId);

      const participants = somethingFromSome(
        (v) => v.map((w) => reach.formatAddress(w)),
        []
      )(await ctc.v.participants());
      if (participants.includes(state.acc.networkAccount.addr)) {
        continue;
      }

      const escrow = getApplicationAddress(appId);

      let account;
      try {
        const res = await getAccountInfo(escrow);
        account = res.account;
      } catch (e) {
        //appService.removeDice(appId);
        continue;
      }
      const { assets: allAssets, amount } = account;
      //console.log(allAssets);
      const assets = (allAssets ?? []).filter(
        (el) => el["asset-id"] !== 88455115
      ); // XXX
      if (!assets) {
        continue;
      }

      const totalAmount = assets.reduce((acc, val) => acc + val.amount, 0);
      //console.log({ appId, totalAmount });

      // add dices
      //if (/*!isShuffle &&*/ amount > 1000000 && totalAmount === 0) {
      //  bounty.push({ assetId: 0, appId, type: "dice" });
      //  continue;
      //}

      /*
      const ctc = state.acc.contract(backend, appId);
      const next = somethingFromSome(
        (v) => reach.bigNumberToNumber(v),
        0
      )(await ctc.v.next());
      //console.log({ assets, amount, totalAmount, next });
      */

      // add sides
      for (let j in assets) {
        const asset = assets[j];
        const amount = asset["amount"];
        const assetId = asset["asset-id"];
        if (amount === 0) {
          //console.log({ appId });
          continue;
        }
        /*
        if (assetId !== next) {
          continue;
        }
        */
        /*
        if (isShuffle) {
          sides.push({
            j,
            assetId,
            appId,
            type: "side",
            next: assetId === next,
          });
        } else {
          */
        //if(assetId !== next) {
        sides.push({
          j,
          assetId,
          appId,
          type: "side",
          //next: assetId === next,
        });
        //}
        /*
        }
        */
      }
    }
    const finalSides = shuffle([...sides, ...bounty]).slice(0); //, GRID_SIZE);
    /*
    if (finalSides.length === GRID_SIZE && bounty.length > 0) {
      finalSides.splice(Math.floor(Math.random() * GRID_SIZE), 1, bounty[0]);
    }
    */
    //console.log({bounty})
    setState({ ...state, sides: finalSides, empty: bounty });
    setLoading(false);
    setNext(null);
    setSide(0);
    setCounter(counter + 1);
  };

  const handleDisconnect = () => setState(initialState);

  const handleDiceCreate = async () => {
    if (selection.length < 6) {
      alert("select 6 assets");
      return;
    }
    // TODO add validation
    setLoading(true);
    //const { info } = await appService.createDice();
    const diceId = [1, ...selection].join("-");
    navigate(`/dice/${diceId}`);
  };

  const handleShuffle = async () => {};

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return state.acc ? (
    <>
      {state.acc && side > 0 && next && (
        <Confetti style={{ zIndex: 9999 }} width={width} height={height} />
      )}
      <Container className="p-0">
        {/* grid */}
        <Box sx={{ flexGrow: 1 }} className="pt-0 pb-0">
          {state.sides ? (
            state.sides.length > 0 ? (
              <>
                <Grid container spacing={0} rowSpacing={0}>
                  <Grid item xs={12} className="text-center">
                    {state.sides
                      .slice(
                        counter % state.sides.length,
                        (counter % state.sides.length) + 1
                      )
                      .map((el) => (
                        <Image
                          fluid
                          src={`/assets/${randomArchirand(el.assetId)}.png`}
                        />
                      ))}
                  </Grid>
                </Grid>
              </>
            ) : (
              ""
            )
          ) : (
            <div
              style={{
                textAlign: "center",
                height: "60vh",
                verticalAlign: "middle",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                color: "black",
                fontSize: "30px",
              }}
            >
              <CircularProgress color="inherit" size={100} />
            </div>
          )}
        </Box>
        <Box className="text-center mt-3 d-md-none"></Box>
        {/* model */}
        {state.acc && display && (
          <Modal
            open={showDiceModal}
            onClose={() => setShowDiceModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box style={style}>
              <Grid container>
                {display.map((el, i) => (
                  <Grid
                    item
                    xs={2}
                    style={
                      {
                        //background: 'url(/assets/0.png)',
                        //backgroundSize: 'cover'
                      }
                    }
                  >
                    {el.next ? (
                      <Image
                        style={{
                          opacity: "0.1",
                        }}
                        fluid
                        src={`/assets/${randomArchirand(el.assetId)}.png`}
                      />
                    ) : (
                      <Image fluid src={`/assets/0${i}.png`} />
                    )}
                  </Grid>
                ))}
              </Grid>
              <ButtonGroup size="lg" className="w-75 mt-5">
                <Button
                  variant="success"
                  disabled={loadingClaim}
                  onClick={() => {
                    setGoingToDice(true);
                    const ctc = state.acc.contract(
                      diceBackend,
                      display[0].appId
                    );
                    ctc.a
                      .ppc(reach.parseCurrency(REACT_APP_PPC_DICE_GO))
                      //new Promise((resolve) => setTimeout(resolve, 1000))
                      .then(() => navigate(`/${display[0].appId}`))
                      .catch(() => setGoingToDice(false));
                  }}
                >
                  {!goingToDice ? (
                    <div
                      style={{
                        fill: "white",
                        display: "flex",
                        alignItems: "baseline",
                        justifyContent: "center",
                      }}
                    >
                      Go to dice&nbsp;
                      <Icon name="algo" size={13} />
                      &nbsp;1
                    </div>
                  ) : (
                    "Processing..."
                  )}
                </Button>
              </ButtonGroup>
            </Box>
          </Modal>
        )}
        {state.acc && side > 0 && next && (
          <>
            <Modal
              open={true}
              //onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box style={style}>
                <Image
                  fluid
                  src={`/assets/${randomArchirand(next.asset?.index)}.png`}
                />
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="mt-3"
                >
                  Awesome!
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {next.asset?.params?.name} (1 of {next.asset?.params?.total}) /{" "}
                  {next.asset?.index} <br />
                  <a
                    href={explorerLink(next.asset?.index)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View on Algoexplorer
                  </a>
                </Typography>
                <ButtonGroup size="lg" className="w-75 mt-5">
                  <Button variant="warning" onClick={handleClose}>
                    Close
                  </Button>
                  {false && (
                    <Button
                      disabled={loadingClaim}
                      onClick={() => {
                        /*
                      console.log("CLAIM");
                      setLoadingClaim(true);
                      //state.acc.tokenAccept(reach.bigNumberify(next.asset.index))
                      reach
                        .transfer(state.acc, state.acc, 0, next.asset.index)
                        .then(() =>
                          state.acc
                            .contract(diceBackend, side)
                            .a.touch()
                            .then(() => loadAssets())
                            .finally(() => {
                              setLoadingClaim(false);
                              setSpeed(1000);
                            })
                        );
                    */
                      }}
                    >
                      {loadingClaim ? (
                        <>
                          <Spinner animation="grow" size="sm" />
                          Processing...
                        </>
                      ) : (
                        <div
                          style={{
                            fill: "white",
                            display: "flex",
                            alignItems: "baseline",
                            justifyContent: "center",
                          }}
                        >
                          Buy&nbsp;
                          <Icon name="algo" size={13} />
                          &nbsp;{next.price}
                        </div>
                      )}
                    </Button>
                  )}
                </ButtonGroup>
              </Box>
            </Modal>
          </>
        )}
      </Container>
      {/* shuffle button */}
      {state.acc && state.sides && state.sides.length > 0 && side === 0 && (
        <>
          <div
            style={{
              position: "fixed",
              bottom: "0px",
              left: "0px",
              width: "100%",
            }}
          >
            <Box sx={{}}>
              <Button
                disabled={loading}
                variant="info"
                size="lg"
                style={{
                  borderRadius: "0px",
                }}
                onClick={async () => {
                  setLoading(true);
                  const i = Math.floor(Math.random() * state.sides.length);
                  const { appId, type } = state.sides[i];
                  const ctc = state.acc.contract(diceBackend, appId);
                  const next = somethingFromSome((v) =>
                    reach.bigNumberToNumber(v)
                  )(await ctc.v.next());
                  const asset = await getAsset(next);

                  console.log({ next, asset });

                  if (type === "dice") {
                    console.log("SMASH");
                    ctc.p.Bob({
                      signal: () => {
                        appService
                          .removeDice(appId)
                          .then(() => loadAssets())
                          .finally(() => setLoading(false));
                      },
                    });
                  } else {
                    // side
                    console.log("SHUFFLE");
                    new Promise((resolve) =>
                      setTimeout(() => {
                        setSpeed(300);
                        resolve();
                      }, 1000)
                    )
                      .then(() =>
                        reach
                          .transfer(state.acc, state.acc, 0, next)
                          .then(() => ctc.a.touch())
                      )
                      //ctc.a.ppc(reach.parseCurrency(REACT_APP_PPC_SHUFFLE))
                      //true
                      /*
                  .then(() => ctc.a.touch())
                  .then(() =>
                    ctc.p.Bob({
                      signal: () => {
                        appService
                          .removeDice(appId)
                          .then(() => loadAssets())
                          .finally(() => setLoading(false));
                      },
                    })
                  )
                  */
                      .then(() => setSpeed(100))
                      .then(() => setSide(appId))
                      .catch(() => {})
                      .finally(() => {
                        setSpeed(1000);
                        setNext({ ...asset });
                        setLoading(false);
                      });
                  }
                }}
                className="w-100 text-light text-weight-bold"
              >
                {!loading ? (
                  <div
                    style={{
                      fill: "white",
                    }}
                  >
                    Shuffle
                  </div>
                ) : (
                  "Shuffling..."
                )}
              </Button>
              <Button
                disabled={true}
                variant="info"
                style={{
                  borderRadius: "0px",
                  background: "lightgrey",
                  border: "0px",
                }}
                onClick={() => {
                  setLoading(true);
                  const i = Math.floor(Math.random() * state.sides.length);
                  const { appId, type } = state.sides[i];
                  const ctc = state.acc.contract(diceBackend, appId);
                  if (type === "dice") {
                    console.log("SMASH");
                    ctc.p.Bob({
                      signal: () => {
                        appService
                          .removeDice(appId)
                          .then(() => loadAssets())
                          .finally(() => setLoading(false));
                      },
                    });
                  } else {
                    // side
                    console.log("SHUFFLE");
                    new Promise((resolve) =>
                      setTimeout(() => {
                        setSpeed(90);
                        resolve();
                      }, 1000)
                    )
                      .then(
                        () =>
                          //ctc.a.ppc(reach.parseCurrency(REACT_APP_PPC_SHUFFLE))
                          reach.transfer(
                            state.acc,
                            state.acc,
                            0,
                            next.asset.index
                          )
                        //.then(() => ctc.a.touch())
                        //true
                      )
                      /*
                  .then(() => ctc.a.touch())
                  .then(() =>
                    ctc.p.Bob({
                      signal: () => {
                        appService
                          .removeDice(appId)
                          .then(() => loadAssets())
                          .finally(() => setLoading(false));
                      },
                    })
                  )
                  */
                      //.then(() => setSpeed(100))
                      //.then(() => setSide(appId))
                      .finally(() => {
                        setSpeed(100);
                        setLoading(false);
                      });
                  }
                }}
                className="w-100 text-light text-weight-bold"
              >
                <div
                  style={{
                    fill: "white",
                    background: "lightgrey",
                  }}
                >
                  <img
                    className="w-50"
                    style={{
                      height: "1.25rem",
                    }}
                    src={POW}
                  />
                </div>
              </Button>
            </Box>
          </div>
        </>
      )}
    </>
  ) : addr ? (
    <div
      style={{
        textAlign: "center",
        height: "60vh",
        verticalAlign: "middle",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        color: "black",
        fontSize: "30px",
      }}
    >
      <CircularProgress color="inherit" size={100} />
    </div>
  ) : (
    <Box
      style={{
        display: "flex",
        height: "90vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      Connect wallet to view page
    </Box>
  );
}

export default App;
